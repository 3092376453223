<template>
    <div class="address-container">
        <div class="head-con">
            <h3>收货地址</h3>
            <div class="addcon" @click="addAddress">
                <p>新增收货地址</p>
                <el-icon><plus /></el-icon>
            </div>
        </div>
        <el-dialog v-model="DialogVisible" :title="addlabel?'新增收货地址':'编辑收货地址'">
            <el-form :model="form" label-width="120px" label="left">
                <el-form-item label="所在地址">
                    <el-cascader
                        v-model="form.place"
                        :options="addressOptions"
                        placeholder="请选择省/市/区"
                        size="medium"
                        style="width:100%;"
                        ></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址">
                   <el-input
                        v-model="form.address"
                        :rows="2"
                        type="textarea"
                        placeholder="请输入信息地址信息，如小区名称、楼栋号、单元等信息"
                    />
                </el-form-item>
                <el-form-item label="收货人">
                   <el-input
                        v-model="form.user_name"
                        placeholder="请输入收货人姓名"
                    />
                </el-form-item>
                <el-form-item label="手机号码">
                   <el-input
                        v-model="form.mobile"
                        placeholder="请输入收货人姓名"
                    />
                </el-form-item>
                <el-form-item>
                   <div>
                        <el-checkbox v-model="checkbool">设为默认地址</el-checkbox>
                    </div>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="foot-con">
                    <el-button @click="DialogVisible =false" size="mini">取消</el-button>
                    <el-button type="danger" @click="saveAddress" size="mini">保存</el-button>
                </div>
            </template>
        </el-dialog>

        <div class="address-content" v-if="datalist.length >0">
            <div class="item" v-for="item in datalist" :key="item.id">
                <div class="l-con">
                    <div class="section">
                        <span>收货人：</span><p>{{item.name}}</p>
                    </div>
                    <div class="section1">
                        <span>收货地址：</span><p>{{item.address_xq}}</p>
                    </div>
                    <div class="section1">
                        <span>联系电话：</span><p>{{item.tel}}</p>
                    </div>
                </div>
                <div class="r-con">
                    <div class="top-section">
                        <el-button type="text" @click="modifyAddress(item)">修改</el-button>
                        <el-button type="text" @click="delAddress(item.id)">删除</el-button>
                    </div>
                    <div class="bottom-section">
                        <el-button type="danger" class="btn" size="mini" v-if="item.is_default==1">默认地址</el-button>
                        <el-button class="btn" size="mini" v-else @click="setDefault(item)">
                            设为默认
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="no-content" v-else>
            <img src="@/assets/image/no_address.png"/>
            <p>你还没有收货地址哦~</p>
        </div>
    </div>
</template>
<script>
import { Plus } from '@element-plus/icons'
import {ref,onMounted, reactive} from 'vue'
import {getData} from '@/utils/req'
import { ElMessage } from 'element-plus'
export default {
    components:{
        Plus
    },
    setup(){
        let DialogVisible = ref(false)
        let pc_user = ref('')
        let addressOptions = ref('')
        let datalist = ref('')
        let checkbool = ref(false)
        let addlabel = ref(true)
        let addr_id = ref('')
        let form = reactive({
            place:'',
            address:'',
            user_name:'',
            mobile:'',
            is_default:'',
        })
        onMounted(()=>{
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
            getaddress()
        })
        const axios =()=>{
            const data ={
                module: 'app_pc',
                action: 'address',
                m: 'index',
                access_id:pc_user.value.access_id
            }
            getData(data).then(res=>{
                if(res.code ===200){
                    datalist.value = res.data
                }
            })
        }
        const getaddress =()=>{
            const data = {
                module:'app_pc',
                action:'address',
                m:'AddressManagement',
                access_id:pc_user.value.access_id,
            }
            getData(data).then(res=>{
                if(res.code ==200){
                    addressOptions.value = res.data
                }
            })
        }
        const addAddress = ()=>{
            DialogVisible.value = true
            resetTmp()
        }
        const setDefault = (item) =>{
            const data= {
                module: 'app_pc',
                action: 'address',
                m: 'set_default',
                access_id: pc_user.value.access_id,
                addr_id: item.id
            }
            getData(data).then(res=>{
                if(res.code ==200){
                    ElMessage.success({
                        message:'默认地址设置成功',
                        duration:1500
                    })
                    axios()
                }
            })
        }
        const resetTmp = ()=>{
            form.place = ''
            form.address = ''
            form.user_name = ''
            form.mobile = ''
            checkbool.value = false
        }
        const modifyAddress =(item)=>{
            resetTmp()
            form.address = item.address
            form.user_name = item.name
            form.mobile = item.tel
            addr_id.value =item.id
            checkbool.value = item.is_default==1?true:false
            DialogVisible.value = true

            // 修改
            addlabel.value = false
        }   
        const saveAddress =()=>{
            if(addlabel.value){
                if(!form.place){
                    ElMessage.error({
                        message:'请选择所在地区',
                        duration:1500
                    })
                    return
                }
                if(!form.address){
                    ElMessage.error({
                        message:'请填写详细地址',
                        duration:1500
                    })
                    return
                }
                if(!form.user_name){
                    ElMessage.error({
                        message:'用户名不能为空',
                        duration:1500
                    })
                }
                if(!form.mobile){
                    ElMessage.error({
                        message:'手机号不能为空',
                        duration:1500
                    })
                }
                form.place = form.place.join(',')
                form.is_default = checkbool.value?1:0
                let data= {
                    module: 'app_pc',
                    action: 'address',
                    m: 'SaveAddress',
                    access_id: pc_user.value.access_id,
                }
                data = Object.assign(data,form)
                getData(data).then(res=>{
                    if(res.code ==200){
                        DialogVisible.value = false
                        axios()
                    }
                })
            }
            else{
                if(!form.place){
                    ElMessage.error({
                        message:'请选择所在地区',
                        duration:1500
                    })
                    return
                }
                form.place = form.place.join(',')
                form.is_default = checkbool.value?1:0

                let data = {
                    module: 'app_pc',
                    action: 'address',
                    m: 'up_adds',
                    access_id: pc_user.value.access_id,
                    addr_id:addr_id.value
                }
                data = Object.assign(data,form)
                getData(data).then(res=>{
                    if(res.code ==200){
                        ElMessage.success({
                            message:'修改成功',
                            duration:1500
                        })
                        axios()
                        DialogVisible.value = false
                    }
                })
            }
            
        }
        const delAddress =(id) =>{
            const data ={
                module: 'app_pc',
                action: 'address',
                m: 'del_adds',
                access_id: pc_user.value.access_id,
                addr_id: id
            }
            getData(data).then(res=>{
                if(res.code ===200){
                    ElMessage.success({
                        message:'地址删除成功',
                        duration:1500
                    })
                    axios()
                   
                }
            })
        }
        const changebool =(e) =>{
            console.log(e,'xxxx')
        }
        return {
            DialogVisible,
            checkbool,
            form,
            datalist,
            addressOptions,
            addlabel,
            saveAddress,
            addAddress,
            delAddress,
            modifyAddress,
            setDefault,
            changebool 
        }
    }
}
</script>
<style scoped>
    .address-container{
        width: 100%;
        height: 100%;
        background: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .address-container .head-con{
        width: 100%;
        height: 56px;
        border-bottom: 1px solid #E6E6E6;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .address-container .head-con .addcon{
        display: flex;
        align-items: center;
        padding-right: 15px;
    }
    .address-container .head-con .addcon p{
        color: #D4282D;
        font-size: 14px;
        line-height: 14px;
        cursor: pointer;
        padding-right: 5px;
    }
    .address-container .head-con h3{
        color: #020202;
        font-size: 16px;
        font-weight: bold;
        padding-left: 16px;
        line-height: 56px;
    }
    ::v-deep .el-dialog__header{
        border-bottom: 1px solid #e6e6e6;
    }
    ::v-deep .el-dialog__title{
        color: #000;
        font-weight: bold;
        font-weight: 16px;
    }
    ::v-deep .el-dialog__footer{
        height: 68px;
        background: #f6f6f6;
    }
    .address-content{
        flex: 1;
        width: 100%;
    }
    .address-content .item{
        border:1px solid #eee;
        margin: 15px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .address-content .item .section{
        display: flex;
        align-items: center;
    }
    .address-content .item .section span{
        font-size: 14px;
        color: #020202;
        line-height: 14px;
        font-weight: bold;
        letter-spacing: 5px;
    }
    .address-content .item .section1{
        display: flex;
        align-items: center;
    }
    .r-con .top-section{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .r-con .bottom-section .btn{
        width: 66px;
        height: 24px;
        padding: 0;
        text-align: center;
        line-height: 24px;
        border-radius: 0;
        margin-top: 10px;
    }
    .no-content{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .no-content p{
        font-size: 16px;
        line-height: 16px;
        color: #888888;
        margin-top: 20px;
    }
    .foot-con .el-button{
        width: 112px;
        height: 36px;
        box-sizing: border-box;
        cursor: pointer;
        margin-left: 10px;
        font-size: 16px;
        border-radius: 0;
    }
</style>